import React, { useState } from 'react';
import axios from 'axios';
import logoGif from '../graphics/logo.gif';
import RecordButton from '../audio_button/audioButton';
const Chatbot = () => {
  const [schema,setSchema] =useState(null);

  
  const [inputMessage, setInputMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [transcription, setTranscription] = useState(null);
  const [docResponse,setdocResponse]=useState(null);
  const [isExtracted, setIsExtracted] = useState(false);
  

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };
  const handlextraction = () => {
    // Your logic to fetch or set the schema
    // For example, you can use an API call and update the state
    // setSchema(...);

    // Set the state to indicate that extraction is done
    setIsExtracted(!isExtracted);
  };

  const handleSendMessage = async () => {
    try {
      setChatMessages((prevMessages) => [...prevMessages, { content: inputMessage, type: 'user' }]);

      const response = await axios.post(
        'https://nurenai2backend.azurewebsites.net/api/get-pdf/',
        {
          message: inputMessage,
          zipName: "11055e45-85e8-49c7-ab5c-f160d1733d88.zip",
          prompt:'prompt'
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const responseData = response.data;

      setChatMessages((prevMessages) => [
        ...prevMessages,
        { content: responseData.answer, type: 'bot', transcription, docResponse },
        { content: '.', isSeparator: true },
      ]);
      setInputMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
  
      

      <div>
 <div
  style={{
    height: '90vh',
  
    overflowY: 'auto',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#f5f5f5',
    backgroundImage: `url("${logoGif}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center', 
    // Set the repeat property to create a never-ending effect
  }}
>
    {chatMessages.map((message, index) => (
      <div
        key={index}
        style={{
     
          marginBottom: '10px',
          padding: '8px',
          borderRadius: '8px',
          backgroundColor: message.type === 'user' ? '#3498db' : '#0D1C5D',
          color: '#fff',
        }}
      >
        {message.content}
        {message.type === 'bot' && (
      <div>
        <p>Transcription: {message.transcription}</p>
        <p>Response: {message.docResponse}</p>
        <p>..</p>
      </div>
    )}
      </div>
    ))}
  </div>
  <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
    <input
      className='name_pass_cont'
      type="text"
      value={inputMessage}
      onChange={handleInputChange}
      placeholder="Type your message..."
      style={{ flex: '1', padding: '8px', borderRadius: '8px', border: '1px solid #ccc', marginRight: '10px', height: '100%', }}
    />
    <button
      onClick={handleSendMessage}
      style={{
       
        padding: '8px',
        borderRadius: '8px',
        backgroundColor: '#3498db',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      Send
    </button>
   <RecordButton zipName={'13340e36-c484-4969-983c-974c763c1e3c'} onTranscription={setTranscription} onDocResponse={setdocResponse} docResponse={docResponse} setSchema={setSchema} />
    {
      
      <button
      onClick={handlextraction}
      style={{
        padding: '8px',
        borderRadius: '8px',
        backgroundColor: '#3498db',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      Extract
    </button>
   
    }
    {isExtracted && (
        <div>
          {schema && (
            <pre>{JSON.stringify(schema, null, 2)}</pre>
            // The second argument (null) is a replacer function or array that alters the behavior of the stringification.
            // The third argument (2) specifies the number of spaces to use for indentation.
          )}
        </div>
      )}
  
  </div>
</div>

  );
};

export default Chatbot;
