import React from "react";
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { updatePassword } from 'firebase/auth';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setUser, clearUser } from './redux_comp/authActions';

const firebaseConfig = {
  apiKey: "AIzaSyBPsLD_NgSwchMrpG2U81UsH_USQGSiNZU",
  authDomain: "nurenai.firebaseapp.com",
  projectId: "nurenai",
  storageBucket: "nurenai.appspot.com",
  messagingSenderId: "667498046930",
  appId: "1:667498046930:web:cb281b053ddc016e18940b"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const VerifyMail=()=>{
    const dispatch = useDispatch();
    const handleSignInWithEmailLink = async (email) => {
        try {
          await signInWithEmailLink(auth, email, window.location.href);
         
          window.localStorage.removeItem('emailForSignIn');
          await auth.currentUser.reload();
    
          if (auth.currentUser && auth.currentUser.emailVerified) {
            const userUID = auth.currentUser.uid;
            const usersCollection = collection(db, 'users');
            const userQuery = query(usersCollection, where('userId', '==', userUID));
            const querySnapshot = await getDocs(userQuery);
            if (!querySnapshot.empty){
              // Access specific fields from the query snapshot
              const userData = querySnapshot.docs[0].data();
              const password = userData.password;
              console.log("password:",password);
      
              await updatePassword(auth.currentUser, password);
            }
            setUser(auth.currentUser);
          } else {
            // Handle the case where email is not verified
          }
        } catch (error) { 
          console.error('Sign-In with Email Link Error:', error.message);
        }
      };
      
      useEffect(() => {
        const handleAuthStateChange = async (user) => {
          if (user) {
            if (user.emailVerified) {
              dispatch(setUser(user));
              
              // Fetch user profile data from Firestore
            } else {
              // Handle the case where email is not verified
            }
          } else {
            dispatch(clearUser());
          } 
      
          if (isSignInWithEmailLink(auth, window.location.href)) {
            const urlSearchParams = new URLSearchParams(window.location.search);
            let email = urlSearchParams.get('email');
            
            if (!email) {
              email = await new Promise((resolve) => {
                const userProvidedEmail = window.prompt('Please provide your email for confirmation');
                resolve(userProvidedEmail);
              });
            }
      
            await handleSignInWithEmailLink(email);
          }
        };
        
        const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
      
        // Include 'dispatch' in the dependency array
        return () => {
          unsubscribe();
        };
      }, [dispatch]); 

return(
<div className="about_us_main">

<h1>Email verified. You can successfully close this tab now</h1>
<h1>WEEEEEEES</h1>
<h1>Email verified. You can successfully close this tab now</h1>
</div>
);
};
export default VerifyMail;