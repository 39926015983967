import React, { useState, useEffect } from 'react';
import './DynamicText.css';

const TypingText = () => {
  const [text, setText] = useState('');
  const messages = ['Mentha', 'Farming', 'Supply Chain','Culture'];

  useEffect(() => {
    let currentMessageIndex = 0;
    let currentCharacterIndex = 0;
    let isDeleting = false;

    const typingInterval = setInterval(() => {
      const currentMessage = messages[currentMessageIndex];
      const currentCharacter = currentMessage.charAt(currentCharacterIndex);

      if (isDeleting) {
        if (currentCharacterIndex === 0) {
          isDeleting = false;
          currentMessageIndex = (currentMessageIndex + 1) % messages.length;
        } else {
          setText((prevText) => prevText.slice(0, -1));
          currentCharacterIndex--;
        }
      } else {
        if (currentCharacterIndex === currentMessage.length) {
          isDeleting = true;
        } else {
          setText((prevText) => prevText + currentCharacter);
          currentCharacterIndex++;
        }
      }
    }, 200); // Adjust the typing speed here

    return () => {
      clearInterval(typingInterval);
    };// eslint-disable-next-line
  }, []);

  return (
    <div style={{display:'flex',justifyContent:'center'}}>
        <p>Nuren AI</p>
    <div className="typing-text">
      <p>{text}</p>
    </div>
    </div>
  );
};

export default TypingText;
