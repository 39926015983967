import React from 'react';

import './left_chat.css'
const LeftChat=()=>{

return(
    <div style={{display:'flex',flexDirection:'column',justifyContent: 'center', alignItems:'center'}}>
        <div><h1>.</h1></div>
    
    </div>
);
};
export default LeftChat;
