import React, { useState, useRef } from 'react';
import axios from 'axios';
import TextToSpeechButton from './ttsButton';

const RecordButton = ({ zipName, onTranscription, onDocResponse, docResponse, setSchema})  => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);

  const audioChunksRef = useRef([]);

  const onStartRecording = (stream) => {
    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        audioChunksRef.current.push(e.data);
      }
    };

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      sendAudioToBackend(audioBlob);
      audioChunksRef.current = []; // Clear recorded audio chunks
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
  };

  const onStopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
    }

    setIsRecording(false);
  };

  const handleRecordClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      setIsRecording(true);
      onStartRecording(stream);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const handleStopClick = () => {
    onStopRecording();
  };

  const sendAudioToBackend = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob);
  
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/upload-audio/', formData, {
        data: {
           zipName: zipName,
        },
        headers: {
       'Content-Type': 'multipart/form-data',
        },
    });

  
      // Handle response from the backend (if needed)
      onTranscription(response.data.transcription);
      onDocResponse(response.data.result_from_query);
      setSchema(response.data.schema);
  
      console.log(response)
    } catch (error) {
      console.error('Error sending audio to backend:', error);
    }
  };
  
  return (
    <div className='recordButtonContainer'>
    <div className='displayBox'>
   
    </div>
    <div className='recordButton'>
      {isRecording ? (
        <div>
          <button onClick={handleStopClick}>Stop Recording</button>
        </div>
      ) : (
        <div>
          <button onClick={handleRecordClick}>Start Recording</button>
        </div>
      )}
    </div>
    <TextToSpeechButton prompt={docResponse}/>
    </div>
  );
};

export default RecordButton;
