  import React from 'react';
  import { initializeApp } from 'firebase/app';
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
  import VerifyMail from './verifyMail.js';
  import './App.css'
  import ContactUs from './company/ContactUs.js';
  import PrivacyPolicy from './company/PrivacyPolicy.js';
  import RefundandCancellation from './company/RefundandCancellation.js';
  import ShipandDelivery from './company/ShipandDelivery.js';
  import TermsConditions from './company/TermsandConditions.js';

  import SignupPage from './auth/signupPage.js';
  import About from './about_us/about_us.js';
  import Personal from './personal/personalize_bot.js';
  import Login from './auth/login.js';
  import Chatbot from './elements/chatbot'
  import Navigation from './elements/navbar';
  import LeftChat from './elements/left_chat.js';
  import { BrowserRouter, Routes, Route } from 'react-router-dom';
  
  import { useState, useEffect } from 'react';
  import Profile from './elements/profile.js';
  import { useSelector, useDispatch } from 'react-redux';
  import { setUser, clearUser } from './redux_comp/authActions';
  
  const firebaseConfig = {
    apiKey: "AIzaSyBPsLD_NgSwchMrpG2U81UsH_USQGSiNZU",
    authDomain: "nurenai.firebaseapp.com",
    projectId: "nurenai",
    storageBucket: "nurenai.appspot.com",
    messagingSenderId: "667498046930",
    appId: "1:667498046930:web:cb281b053ddc016e18940b"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const App = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    
    
    const dispatch = useDispatch();
    const user = useSelector((state) => state);
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          if (user.emailVerified) {
            dispatch(setUser(user));
          } else {
            console.log('Email not verified. Please check your email to complete the verification process.');
            dispatch(clearUser());
          }
        } else {
          dispatch(clearUser());
        }
      });
  
      return () => unsubscribe();
    }, [dispatch]);
    const handleLogout = () => {
      // Dispatch the action to clear the user from the Redux store
      dispatch(clearUser());
      
      // Your existing logout logic
      if (user) {
        auth.signOut().then(() => {
          // Dispatch the action to clear the user from the Redux store
          
        }).catch((error) => {
          console.error('Logout Error:', error.message);
        });
      }
    };
    
    

    return (
      <BrowserRouter>
  {user && user.emailVerified && (
    <Navigation buttonclick={handleLogout} user={user} />
  )}

  <div className='dynamic_button'>
    <Routes>
      <Route
        path="/"
        element={
          user ? (
            <div>
              {user.emailVerified ? (
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', margin: '0 auto', justifyContent: 'center' }}>
                  <div>
                    <LeftChat />
                  </div>
                  <div style={{ width: '60%' }}>
                    <Chatbot />
                  </div>
                  <div></div>
                </div>
              ) : (
                <p className='sign_log_page'>Email not verified. Please check your email to complete the verification process.</p>
              )}
            </div>
          ) : (
            <Login setEmail={setEmail} setPassword={setPassword} setuser={setUser} clearuser={clearUser} email={email} user={user} password={password} />
          )
        }
      />

      {user && user.emailVerified && (
        <Route path="/profile" element={<Profile />} />
      )}

      {user && user.emailVerified && (
        <Route path="/Personal" element={<Personal />} />
      )}

      <Route path="/About" element={<About />} />
      <Route path="/verify-mail" element={<VerifyMail />} />
      <Route path="/signup" element={<SignupPage setuser={setUser} />} />
      <Route path="/ContactUs" element={<ContactUs />} />
      <Route path="/TermsandConditions" element={<TermsConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/RefundandCancellation" element={<RefundandCancellation />} />
      <Route path="/ShipandDelivery" element={<ShipandDelivery />} />
      
      
    </Routes>
  </div>
</BrowserRouter>
  
    );
  };

  export default App;
