import React, { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';

import { Link} from 'react-router-dom';


import { useDispatch } from 'react-redux';

const firebaseConfig = {
  apiKey: "AIzaSyBPsLD_NgSwchMrpG2U81UsH_USQGSiNZU",
  authDomain: "nurenai.firebaseapp.com",
  projectId: "nurenai",
  storageBucket: "nurenai.appspot.com",
  messagingSenderId: "667498046930",
  appId: "1:667498046930:web:cb281b053ddc016e18940b"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


const Login = ({setEmail,setPassword,setuser,clearuser,email,user, password}) => {
  
  const dispatch = useDispatch();
      
    
      useEffect(() => {
        const handleAuthStateChange = async (user) => {
          if (user) {
            if (user.emailVerified) {
              dispatch(setuser(user));
              
        // Fetch user profile data from Firestore
            } else {
              // Handle the case where email is not verified
            }
          } else {
            dispatch(clearuser());
          } 
    
          
        };
        
        const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
    
        return () => unsubscribe();
      }, );

      

     
    
      const handleLogin = async () => {
        try {
          console.log('Attempting to log in with:', email, password);
    
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          const loggedInUser = userCredential.user;
    
          if (!loggedInUser.emailVerified) {
            alert('Please verify your email before signing in.');
            return;
          }
    
          setuser(loggedInUser);
          
        } catch (error) {
          console.error('Login Error:', error);
        }
      };
    
      
    
      /*const handleGoogleSignIn = async () => {
        try {
          const provider = new GoogleAuthProvider();
          const userCredential = await signInWithPopup(auth, provider);
          const googleUser = userCredential.user;
    
          if (!googleUser.emailVerified) {
            alert('Please verify your email before signing in.');
            return;
          }
    
          setuser(googleUser);
        } catch (error) {
          console.error('Google Sign-In Error:', error.message);
        }
      };*/

  return (
    
    <div className='sign_log_page'>
      <div className='sign_log_form'>
        <div>
          <input
            className='name_pass_cont'
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <input
            className='name_pass_cont'
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <button className='button-49' onClick={handleLogin}>Login</button>
        </div>
        {/*
        <div style={{ marginTop: '5px' }}>
          <button className='button-49' onClick={handleGoogleSignIn}>Sign In with Google</button>
        </div>*/}
        <div style={{ marginTop: '5px' }}>
          <button className='button-49' ><Link to='/signup'>Register</Link></button>
        </div>
      
      </div>
    </div>
  );
};

export default Login;
