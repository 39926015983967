import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

import { sendSignInLinkToEmail } from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { getDocs, query, where } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBPsLD_NgSwchMrpG2U81UsH_USQGSiNZU",
  authDomain: "nurenai.firebaseapp.com",
  projectId: "nurenai",
  storageBucket: "nurenai.appspot.com",
  messagingSenderId: "667498046930",
  appId: "1:667498046930:web:cb281b053ddc016e18940b"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const SignupPage = ({setuser}) => {
  const [errorMessage,seterrorMessage]=useState('');
  const [phoneNumberExists, setPhoneNumberExists] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const [usernameExists, setUsernameExists] = useState(false);
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const checkIfPhoneNumberExists = async (phoneNumber) => {
    // Check if the phone number is a valid 10-digit number
    const isValidPhoneNumber = /^\d{10}$/.test(phoneNumber);
  
    if (!isValidPhoneNumber) {
      return 'Invalid phone number format. Please enter a 10-digit number.';
    }
  
    try {
      // Query Firestore to check if the phone number exists
      const querySnapshot = await getDocs(query(collection(db, 'users'), where('phoneNumber', '==', phoneNumber)));
  
      if (!querySnapshot.empty) {
        return 'Phone number is already taken.';
      }
  
      return ''; // No error
    } catch (error) {
      console.error('Error checking phone number existence:', error.message);
      return 'An error occurred while checking the phone number.';
    }
  };
  
  
  const checkIfEmailExists = async (email) => {
    try {
      const querySnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', email)));
      return !querySnapshot.empty;
    } catch (error) {
      console.error('Error checking email existence:', error.message);
      return true;
    }
  };
  
  const checkIfUsernameExists = async (username) => {
    try {
      // Query Firestore to check if the username exists
      const querySnapshot = await getDocs(query(collection(db, 'users'), where('username', '==', username)));
      return !querySnapshot.empty;
    } catch (error) {
      console.error('Error checking username existence:', error.message);
      return true; // Assume username exists to prevent sign-up in case of an error
    }
  };
  const sendEmailVerificationLink = async (email) => {
    const actionCodeSettings = {
      url: `http://localhost:3000/verify-mail?email=${encodeURIComponent(email)}`,
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
    } catch (error) {
      console.error('Email Verification Link Error:', error.message);
    }
  };
  const saveUserProfileToDatabase = async (userId, profileData) => {
    try {
      // Add user data to Firestore 'users' collection
      const docRef = await addDoc(collection(db, "users"), {
        userId: userId, 
        fullName: profileData.fullName,
        username: profileData.username,
        phoneNumber: profileData.phoneNumber,
        money:100,
        email: profileData.email,
        password: profileData.password,
        // Add more fields as needed
      });

      console.log("User profile document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding user profile document: ", e);
    }
  };

  const handleSignup = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Save user profile data to Firestore
      await saveUserProfileToDatabase(user.uid, { fullName, username, phoneNumber, email, password });

      await sendEmailVerificationLink(email);

      alert('Sign-up successful! Check your email for the verification link.');
      setuser(user);
    } catch (error) {
      console.error('Signup Error:', error.message);
    }
  };

  

  return (
    <div className='sign_log_page'>
      <div className='sign_log_form'>
        <div>
          <input
            className='name_pass_cont'
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div>
        <input
          className={`name_pass_cont ${usernameExists ? 'field-error' : ''}`}
          type="text"
          placeholder="Username"
          value={username}
          onChange={async (e) => {
            const newUsername = e.target.value;
            setUsername(newUsername);

            // Check if the new username already exists
            const exists = await checkIfUsernameExists(newUsername);
            setUsernameExists(exists);

            // ... other logic ...
          }}
        />
        {usernameExists && (
          <p className="error-message">Username is already taken</p>
        )}
        </div>
        <div>
          <input
            className={`name_pass_cont ${phoneNumberExists ? 'field-error' : ''}`}
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={async (e) => {
              const newPhoneNumber = e.target.value;
              setPhoneNumber(newPhoneNumber);

              // Check if the new phone number already exists
              const errorMessage = await checkIfPhoneNumberExists(newPhoneNumber);
              
              setPhoneNumberExists(!!errorMessage); // Set the state based on whether there's an error
              seterrorMessage(errorMessage);

              // ... other logic ...
            }}
          />
          {phoneNumberExists && (
            <p className="error-message">{errorMessage}</p>
          )}
        </div>

        <div>
          <input
            className={`name_pass_cont ${emailExists ? 'field-error' : ''}`}
            type="email"
            placeholder="Email"
            value={email}
            onChange={async (e) => {
              const newEmail = e.target.value;
              setEmail(newEmail);

              // Check if the new email already exists
              const exists = await checkIfEmailExists(newEmail);
              setEmailExists(exists);

              // ... other logic ...
            }}
          />
          {emailExists && (
            <p className="error-message">Email is already taken</p>
          )}
        </div>

        <div>
          <input
            className='name_pass_cont'
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {/*<div>
        <label>
            Male
            <input
            
              type="radio"
              value="male"
              checked={selectedGender === 'male'}
              onChange={() => setSelectedGender('male')}
            />
          </label>
          <label>
            Female
            <input
              type="radio"
              value="female"
              checked={selectedGender === 'female'}
              onChange={() => setSelectedGender('female')}
            />
          </label>
          <label>
            Other
            <input
              type="radio"
              value="others"
              checked={selectedGender === 'others'}
              onChange={() => setSelectedGender('others')}
            />
          </label>
          </div>*/}
        <div style={{ marginTop: '10px' }}>
          <button className='button-49' onClick={handleSignup}>Sign Up</button>
         
      </div>
      <div style={{ marginTop: '5px' }}>
         
          <button className='button-49'><Link to='/'>Login</Link></button>
      </div>
    </div>
    </div>
  );
};

export default SignupPage;