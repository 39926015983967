import React from "react";

const Profile=()=>{
return(
<div>

<h1>WEEEEEEES</h1>
<h1>WEEEEEEES</h1>

</div>
);
};
export default Profile;