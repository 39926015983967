import React from "react";
import TypingText from "./DynamicText";
import './about_us.css';
const About=()=>{
return(
    <div>
       
<div className="about_us_main">
<TypingText/>
</div>
</div>
);
};
export default About;