import React, { useState, useEffect } from 'react';
import './navbar.css';

import { useNavigate } from "react-router-dom";
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyBPsLD_NgSwchMrpG2U81UsH_USQGSiNZU",
  authDomain: "nurenai.firebaseapp.com",
  projectId: "nurenai",
  storageBucket: "nurenai.appspot.com",
  messagingSenderId: "667498046930",
  appId: "1:667498046930:web:cb281b053ddc016e18940b"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


const Navigation = ({buttonclick,user}) => {
  const navigate = useNavigate();
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [userName, setUserName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false); 
  const options = [
    { value: "Profile", label: "Profile" },
    { value: "Credits", label: "Credits" },
    { value: "About", label: "About" },
    { value: "logout", label: "Logout" },
  
  ];


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > scrollY && !isScrollingUp) {
        // Scrolling down
        setIsScrollingUp(true);
      } else if (scrollTop < scrollY && isScrollingUp) {
        // Scrolling up
        setIsScrollingUp(false);
      }

      setScrollY(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollingUp, scrollY]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser && auth.currentUser.emailVerified) {
        const userUID = auth.currentUser.uid;
        const usersCollection = collection(db, 'users');
        const userQuery = query(usersCollection, where('userId', '==', userUID));

        try {
          const querySnapshot = await getDocs(userQuery);
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            const name = userData.fullName;
            setUserName(name); // Update the state with the fetched user name
          }
        } catch (error) {
          console.error("Error fetching user data", error);
        }
      }
    };

    fetchUserData(); // Call the function when the component mounts
  }, []);
  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;

    // If "Logout" option is selected, call the buttonclick function
    if (selectedValue === "logout") {
      buttonclick();
      navigate("/");
    } else if (selectedValue === "Profile") {
      navigate("/profile");
    }else if (selectedValue === "About") {
      navigate("/About");
    }else if (selectedValue === "Credits") {
      navigate("/personal");
    }
    

    // Add any other logic based on the selected option if needed
    console.log(`Selected option: ${selectedValue}`);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <>
      <nav className={`navbar ${isScrollingUp ? 'hidden' : ''}`}>
        <ul>
          <div className='left-navbar'>
          <li><a href="https://nurenai.in">Home</a></li>
          <li><a href="https://nurenai.in/about">About</a></li>
          <li><a href="https://nurenai.in/products">Products</a></li>
          </div>
          <div className='right-navbar'>
          {user ? (
              <div className="dropdown-container" onBlur={() => setShowDropdown(false)}>
                <div onClick={toggleDropdown}><span>{userName}</span></div>
                {showDropdown && (
                  <select className="dropdown" onChange={handleOptionChange}>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            ) : (
              <li onClick={buttonclick}><span>Login</span></li>
            )}
          </div>
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
