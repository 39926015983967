import React from "react";
import '../about_us/about_us.css';
const ShipandDelivery=()=>{
return(
    <div>
       
<div className="about_us_main">
    <h1>
Shipping & Delivery Policy
    </h1>
Last updated on 31-01-2024 14:45:25
For International buyers, orders are shipped and delivered through registered international courier
companies and/or International speed post only. For domestic buyers, orders are shipped through
registered domestic courier companies and /or speed post only. Orders are shipped within 3-5 Days days
or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject
to Courier Company / post office norms.
NURENAISSANCE FABRICA PRIVATE LIMITED is not liable for any delay in delivery by the
courier company / postal authorities and only guarantees to hand over the consignment to the courier
company or postal authorities within 3-5 Days days from the date of the order and payment or as per the
delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address
provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during
registration. For any issues in utilizing our services you may contact our helpdesk on 9643393874 or
founder@nurenai.in.
</div>
</div>
);
};
export default ShipandDelivery;