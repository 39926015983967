import React from "react";

import '../about_us/about_us.css';
const ContactUs=()=>{
return(
    <div>
       
<div className="about_us_main">
    <h1>
Contact Us
</h1>
Last updated on 31-01-2024 14:41:01
You may contact us using the information below:
Merchant Legal entity name: NURENAISSANCE FABRICA PRIVATE LIMITED
Registered Address: 3rd Floor, Property No. 44, Regal Building, above Madame Tus, Delhi, Delhi, PIN:
110001
Operational Address: 3rd Floor, Property No. 44, Regal Building, above Madame Tus, New Delhi, Delhi,
PIN: 110001
Telephone No: 9643393874
E-Mail ID: founder@nurenai.in
</div>
</div>
);
};
export default ContactUs;