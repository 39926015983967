import React, { useState, useEffect } from 'react';

const TextToSpeechButton = (props) => {
  const { prompt } = props;
  const [speaking, setSpeaking] = useState(true);

  // Use effect to automatically start speaking when the component mounts
  useEffect(() => {
    const utterance = new SpeechSynthesisUtterance(prompt);
    window.speechSynthesis.speak(utterance);

    // Cleanup function to stop speaking when the component unmounts
    return () => {
      window.speechSynthesis.cancel();
    };
  }, [prompt]);

  // Function to stop speaking
  const stopSpeaking = () => {
    window.speechSynthesis.cancel();
    setSpeaking(false);
  };

  return (
    <div>
      {speaking && <p>Speaking...</p>}
      <button onClick={stopSpeaking}>Stop Speaking</button>
    </div>
  );
};

export default TextToSpeechButton;
